import React from 'react'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import cx from 'classnames'
import { useIntl } from 'intl'
import { useFt } from 'hooks'

import { constants, getTestIdFromText } from 'helpers'

import { useProductCollaborationLabel } from 'modules/collaborations'
import type { Label as LabelType, RebrandLabel, TradingItemType } from 'typings/graphql'

import { Icon, Label } from 'components/dataDisplay'
import GraphicLabels from 'compositions/ProductGraphicLabels/ProductGraphicLabels'

import { useExcludeLabels } from './util'

import messages from './messages'


const PLUS_FREE_GIFT_EXCLUDED_TRADING_TEM_TYPES: TradingItemType[] = [ 'ALaCarte', 'PerfumeVial' ]

export type LabelsProps = {
  labels?: RebrandLabel
  excludeLabels?: LabelType[]
  upchargePrice?: number
  isEcommerce?: boolean
  isSmallCard?: boolean
  discount?: number
  tradingItemType?: TradingItemType
  withGenderLabels?: boolean
  removeZeroCents?: boolean
}

const Labels: React.FunctionComponent<LabelsProps> = (props) => {
  const {
    labels: rebrandLabels, upchargePrice, isEcommerce,
    isSmallCard, excludeLabels, discount, tradingItemType,
    withGenderLabels = false, removeZeroCents,
  } = props

  const isCommerceToolsGWPEnabled = useFt(constants.features.commerceToolsGWP)
  const isAiSummaryEnabled = useFeatureIsOn(constants.features.aiSummary)
  const isAiSummaryCardsFeatureEnabled = useFeatureIsOn(constants.features.aiSummaryCards)

  const isAiSummaryCardsEnabled = isAiSummaryEnabled && isAiSummaryCardsFeatureEnabled

  const intl = useIntl()

  const labels = useExcludeLabels(rebrandLabels, excludeLabels)
  let collaborationLabel = useProductCollaborationLabel({ labels: labels as ProductFragment.Base['rebrandLabels'] })

  const areExtraItemsExist = Boolean(labels?.extra?.length)
  const areMarketingItemsExist = Boolean(labels?.marketing?.length)
  const hasDiscount = typeof discount === 'number' && discount > 0
  const hasAiSummaryLabel = isAiSummaryCardsEnabled && true // TODO: Backend integration (AI reviews summary) - added on 2024-08-27 by girilloid

  if (!areExtraItemsExist && !areMarketingItemsExist && !collaborationLabel && !hasDiscount && !hasAiSummaryLabel) {
    return null
  }

  const mainLabels = []

  if (hasDiscount) {
    mainLabels.push({
      text: intl.formatMessage(messages.discount, {
        discount,
      }),
      bgColor: 'brown-red',
      textColor: 'white',
    })
  }

  if (collaborationLabel) {
    mainLabels.push({
      text: collaborationLabel,
      bgColor: 'black',
      textColor: 'gold-30',
    })
  }

  const items = []

  if (upchargePrice && !isEcommerce) {
    items.push(`+${intl.formatPrice(upchargePrice, { removeZeroCents })}`)
  }

  if (labels?.extra?.includes('EXCLUSIVE')) {
    items.push(intl.formatMessage(messages.exclusive))
  }

  if (withGenderLabels && labels?.extra?.includes('FOR_HER')) {
    items.push(intl.formatMessage(messages.forHer))
  }

  if (withGenderLabels && labels?.extra?.includes('FOR_HIM')) {
    items.push(intl.formatMessage(messages.forHim))
  }

  if (items.length) {
    mainLabels.push({
      text: items.join(' • '),
      bgColor: 'black',
      textColor: 'gold-30',
    })
  }

  const isPlusFreeGift = isCommerceToolsGWPEnabled && labels?.extra?.includes('PLUS_FREE_GIFT')

  if (isPlusFreeGift && isEcommerce && !PLUS_FREE_GIFT_EXCLUDED_TRADING_TEM_TYPES.includes(tradingItemType)) {
    mainLabels.push({
      text: messages.plusFreeGift,
      bgColor: 'gold-70',
      textColor: 'white',
    })
  }

  const hasMainLabels = Boolean(mainLabels.length)
  const graphicLabelsClassName = hasMainLabels ? 'pt-8' : 'pt-16'

  return (
    <>
      <div className="absolute left-0 top-0 z-10 flex flex-col items-start gap-10">
        {
          mainLabels.map(({ text, textColor, bgColor }, index) => (
            <Label
              key={index}
              className="-ml-6 -mt-6"
              title={text}
              bgColor={bgColor}
              textColor={textColor}
              withTail
              data-testid={getTestIdFromText(text, 'Label')}
            />
          ))
        }
        {
          areMarketingItemsExist && (
            <GraphicLabels
              className={cx('pl-8', isSmallCard && 'origin-top-left scale-75', graphicLabelsClassName)}
              items={labels.marketing}
              limit={isSmallCard ? 1 : 2}
            />
          )
        }
      </div>
      {
        hasAiSummaryLabel && (
          <Icon className="z-1 absolute right-10 top-10" name="24/ai-generated" aria-label="AI review summary" />
        )
      }
    </>
  )
}


export default React.memo(Labels)
